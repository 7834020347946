import React from 'react'
import ViewOne from '../../components/layout/ViewOne'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const header = [
  {
    id: 'cash_balance',
    key: 'ledger_accounts',
    name: dictionary().AVIALABLE_BALANCE,
    type: 'Cash_balance',
    isRight: true,
  },
  {
    id: 'name.ar',
    key: 'name.ar',
    name: dictionary().STATION_NAME_AR,
    type: 'text',
  },
  {
    id: 'name.en',
    key: 'name.en',
    name: dictionary().STATION_NAME_EN,
    type: 'text',
  },
  {
    id: 'providerId',
    key: 'providerId',
    name: dictionary().STATION_PROVIDER,
    type: 'text',
  },
  {
    id: 'tips_percentage',
    key: 'tips_percentage',
    name: dictionary().STATION_PERCENTAGE,
    type: 'text',
  },
  {
    id: 'adress',
    key: 'address',
    name: dictionary().ADDRESS,
    type: 'text',
  },
  {
    id: 'city',
    key: 'city',
    name: dictionary().CITY,
    type: 'text',
  },
  {
    id: 'road',
    key: 'road',
    name: dictionary().STATION_ROAD,
    type: 'text',
  },
  {
    id: 'contact_person.name',
    key: 'contact_person.name',
    name: dictionary().STATIONS_CONTACT_NAME,
    type: 'text',
  },
  {
    id: 'contact_person.phone',
    key: 'contact_person.phone',
    name: dictionary().STATIONS_CONTACT_NUMBER,
    type: 'text',
  },
  {
    id: 'toll_weight_modifier',
    key: 'toll_weight_modifier',
    name: dictionary().TOLL_WEIGHT_MODIFIER,
    type: 'text',
  },
  {
    id: 'location.coordinates.0',
    key: 'location.coordinates.0',
    name: dictionary().LONGITUDE,
    type: 'text',
  },
  {
    id: 'location.coordinates.1',
    key: 'location.coordinates.1',
    name: dictionary().LATITUDE,
    type: 'text',
  },
  {
    id: 'available_fuel_types',
    key: 'available_fuel_types',
    name: dictionary().FUEL_TYPES,
    type: 'text',
  },
  {
    id: 'code',
    key: 'code',
    name: dictionary().CODE,
    type: 'text',
  },
  // {
  //   id: 'stations_shift_starting',
  //   key: 'shift_starting',
  //   name: dictionary().SHIFT_START_TIME,
  //   type: 'text',
  // },
  // {
  //   id: 'stations_shifts_number',
  //   key: 'shifts_number',
  //   name: dictionary().NUMBER_OF_SHIFTS_PER_DAY,
  //   type: 'text',
  // },
  {
    id: 'use_liters',
    key: 'use_liters',
    name: dictionary().LITERS,
    type: 'boolean',
  },
  // {
  //   id: 'has_printing',
  //   key: 'has_printing',
  //   name: dictionary().PRINTING,
  //   type: 'boolean',
  // },
  // {
  //   id: 'show_reports',
  //   key: 'show_reports',
  //   name: dictionary().REPORTS,
  //   type: 'boolean',
  // },
  {
    id: 'config.live_ocr.enabled',
    key: 'config.live_ocr.enabled',
    name: 'إعدادات Station OCR ',
    type: 'boolean',
  },
  {
    id: 'config.live_ocr.skip',
    key: 'config.live_ocr.skip',
    name: 'إعدادات Live OCR ',
    type: 'boolean',
  },
  {
    id: 'config.live_ocr.auto_skip',
    key: 'config.live_ocr.auto_skip',
    name: dictionary().ALLOW_AUTO_SKIP,
    type: 'boolean',
  },
  {
    id: 'is_active',
    key: 'is_active',
    name: dictionary().STATUS,
    type: 'boolean',
  },
  // {
  //   id: 'is_demo',
  //   key: 'is_demo',
  //   name: dictionary().TYPE,
  //   type: 'boolean',
  // },
  {
    id: 'odometer_first',
    key: 'odometer_first',
    name: dictionary().ODOMETER,
    type: 'boolean',
  },
  {
    id: 'show_for_drivers',
    key: 'show_for_drivers',
    name: dictionary().SHOW_STATION,
    type: 'boolean',
  },
]

const actions = [
  { action: 'edit', title: dictionary().EDIT },
  {
    type: 'path',
    action: 'add-new-staff',
    title: dictionary().ADD_NEW_STAFF,
  },
  {
    type: 'withdrawModal',
    title: dictionary().WITHDRAW,
  },
]

const Station: React.FC = () => {
  return (
    <>
      <ViewOne
        actions={actions}
        header={header}
        restRoute='/stations'
        dataLocation=''
        title={dictionary().STATION_NAME}
        cashingKey={CASHING_KEY.GET_SINGLE_STATION}
        userType='station-providers'
      />
    </>
  )
}

export default Station
