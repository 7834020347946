import { FormControl, FormLabel, Input, Flex, FormErrorMessage, InputGroup, Checkbox, RadioGroup, Stack, Radio, Grid, GridItem, Text } from '@chakra-ui/react'
import React, { useRef, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Card from '../../components/core/Card'
import SaveButton from '../../components/core/SaveButton'
import Reqiured from '../../components/core/reqiured'
import AutoCompleteInput from '../../components/core/AutoCompleteInput'
import { useStore } from '../../store'
import { ROLES } from '../../constants/ROLES'

const EditStation: React.FC = () => {
  const { role } = useStore()
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/
  const phoneRegex = /^01[0125][0-9]{8}$/

  const { id } = useParams<{ id: string }>()
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
    setValue,
    control,
    watch,
  } = useForm({
    mode: 'onChange',
  })

  const services = watch('services')
  const [showServiceError, setShowServiceError] = useState(false)
  const [fuelTypeIds, setFuelTypeIds] = useState<any[]>([])
  const headerRef = useRef<HTMLDivElement>(null)
  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()
  const stationConfig = watch('station_config')

  const stations = useQuery(['fuel - type', id], () => fetch('GET', `/stations/${id}`), {})
  const stationProviders = useQuery(['station providers', id], () => fetch('GET', `/station-providers`), {})
  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('PATCH', `/stations`, { data: vars }), {
    onSuccess: (): void => {
      onSuccess()
      push(`/stations/${id}`)
    },
    onError,
  })

  useEffect(() => {
    setShowServiceError(false)
  }, [watch('enable_car_wash'), watch('enable_oil_change')])

  useEffect(() => {
    if (stations?.data) {
      const fuels: any[] = []
      if (stations?.data?.available_fuel_types !== undefined) {
        stations?.data?.available_fuel_types.map((item: any) => fuels.push(item?.id))
        setFuelTypeIds(fuels)
      }
      setValue('ar', stations?.data?.name?.ar)
      setValue('en', stations?.data?.name?.en)
      setValue('type', stations?.data?.type)
      setValue('contact_person.name', stations?.data?.contact_person.name)
      setValue('contact_person.phone', stations?.data?.contact_person?.phone)
      setValue('contact_person.email', stations?.data?.contact_person?.email)
      setValue('coordinates', `${stations?.data?.location?.coordinates[1]}, ${stations?.data?.location?.coordinates[0]}`)
      setValue('is_active', stations?.data?.is_active)
      setValue('use_liters', stations?.data?.use_liters)
      setValue('odometer_first', stations?.data?.odometer_first)
      setValue('show_for_drivers', stations?.data?.show_for_drivers)
      setValue('tips_percentage', stations?.data?.tips_percentage)
      setValue('station_config', stations?.data?.config?.live_ocr?.enabled)
      setValue('auto_skip', stations?.data?.config?.live_ocr?.auto_skip)
      setValue('live_ocr', stations?.data?.config?.live_ocr?.skip ? 'allow_skip' : 'no_skip')
    }
  }, [stations?.data, stationProviders?.data])

  useEffect(() => {
    setValue('availableFuelTypeIds', fuelTypeIds)
  }, [fuelTypeIds])

  const editStationData = async () => {
    if (getValues()?.coordinates?.toString().replace(/\s+/g, '')?.split(',').length < 2) {
      alert(`شكل الإحداثيات لابد أن يكون هكذا : العرض,الطول
           مثال :  562,245
      `)
    }

    const formData: any = [
      {
        id: stations?.data?.id,
        name: { ar: getValues()?.ar.trim(), en: getValues()?.en.trim() },
        is_active: getValues()?.is_active,
        contact_person: {
          name: getValues()?.contact_person?.name.trim(),
          phone: getValues()?.contact_person?.phone,
          email: getValues()?.contact_person?.email.trim(),
        },
        location: {
          latitude: Number(getValues()?.coordinates?.toString().replace(/\s+/g, '')?.split(',')[0].trim()),
          longitude: Number(getValues()?.coordinates?.toString().replace(/\s+/g, '')?.split(',')[1].trim()),
        },
        availableFuelTypeIds: getValues()?.availableFuelTypeIds,
        type: 'FUEL',
        use_liters: getValues()?.use_liters,
        odometer_first: getValues()?.odometer_first,
        show_for_drivers: getValues()?.show_for_drivers,
        tips_percentage: Number(getValues()?.tips_percentage),
        config: {
          live_ocr: {
            enabled: stationConfig,
            skip: getValues()?.live_ocr === 'allow_skip',
            auto_skip: getValues()?.auto_skip,
          },
        },
      },
    ]

    mutate(formData)
  }

  const shouldSelectOneService = (): boolean => {
    const throwServiceError = services && !watch('enable_car_wash') && !watch('enable_oil_change')
    setShowServiceError(throwServiceError)
    return !throwServiceError
  }

  useEffect(() => {
    if (!stationConfig) {
      setValue('live_ocr', null)
      setValue('auto_skip', null)
    }
  }, [stationConfig])

  return (
    <Container>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Header
          title={dictionary().EDIT_STATION}
          action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && shouldSelectOneService() && editStationData()} />}
        />
      </Flex>
      <form onSubmit={handleSubmit(editStationData as any)}>
        <Card p='8'>
          <Grid templateColumns='repeat(12, 1fr)' gap={6} mt={['0', '0', '10px', '10px']}>
            <GridItem colSpan={12} pb={5}>
              <Text fontSize='20px' fontWeight='700'>
                {dictionary().MAIN_INFO}
              </Text>
              <hr style={{ margin: '10px 0' }} />
            </GridItem>
            <GridItem colSpan={[12, 12, 6, 4]}>
              <FormControl id={dictionary().STATION_NAME_AR} isInvalid={!!errors.ar}>
                <FormLabel>
                  {dictionary().STATION_NAME_AR} <Reqiured />
                </FormLabel>
                <InputGroup>
                  <Input
                    type='text'
                    {...register('ar', {
                      required: dictionary().REQUIRED,
                      validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>{errors?.ar?.message}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[12, 12, 6, 4]}>
              <FormControl id={dictionary().STATION_NAME_EN} isInvalid={!!errors.en}>
                <FormLabel>
                  {dictionary().STATION_NAME_EN} <Reqiured />
                </FormLabel>
                <InputGroup>
                  <Input
                    type='text'
                    {...register('en', {
                      required: dictionary().REQUIRED,
                      validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>{errors?.en?.message}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[12, 12, 6, 4]}>
              <FormControl id={dictionary().STATIONS_CONTACT_NAME} isInvalid={!!errors?.contact_person?.name}>
                <FormLabel>
                  {dictionary().STATIONS_CONTACT_NAME} <Reqiured />
                </FormLabel>
                <InputGroup>
                  <Input
                    type='text'
                    {...register('contact_person.name', {
                      required: dictionary().REQUIRED,
                      validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>{errors?.contact_person?.name?.message}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[12, 12, 6, 4]}>
              <FormControl id={dictionary().STATIONS_CONTACT_NUMBER} isInvalid={!!errors?.contact_person?.phone}>
                <FormLabel>
                  {dictionary().STATIONS_CONTACT_NUMBER} <Reqiured />
                </FormLabel>
                <InputGroup>
                  <Input
                    type='number'
                    {...register('contact_person.phone', {
                      required: dictionary().REQUIRED,
                      validate: (value) => (!phoneRegex.test(value) ? dictionary().INVALID_PHONE : undefined),
                    })}
                  />
                </InputGroup>

                <FormErrorMessage>{errors?.contact_person?.phone?.message}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[12, 12, 6, 4]}>
              <FormControl id={dictionary().STATIONS_CONTACT_EMAIL} isInvalid={!!errors?.contact_person?.email}>
                <FormLabel>
                  {dictionary().STATIONS_CONTACT_EMAIL} <Reqiured />
                </FormLabel>
                <InputGroup>
                  <Input
                    type='text'
                    {...register('contact_person.email', {
                      required: dictionary().REQUIRED,
                      validate: (value) => (!emailRegex.test(value) ? dictionary().INVALID_EMAIL : undefined),
                    })}
                  />
                </InputGroup>

                <FormErrorMessage>{errors?.contact_person?.email?.message}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[12, 12, 6, 4]}>
              <FormControl id={dictionary().STATION_PERCENTAGE} isInvalid={!!errors?.tips_percentage}>
                <FormLabel>
                  {dictionary().STATION_PERCENTAGE} <Reqiured />
                </FormLabel>
                <InputGroup>
                  <Input
                    type='number'
                    {...register('tips_percentage', {
                      required: dictionary().REQUIRED,
                      // pattern: {
                      //   value: /^\d+$/,
                      //   message: dictionary().THIS_INPUT_ACCEPTED_ONLY_INTEGER_NUMBERS,
                      // },
                      validate: (value) =>
                        value < 0 ? dictionary().THIS_INPUT_CANT_BE_BELOW_ZERO : value > 100 ? dictionary().THIS_INPUT_CANT_BE_OVER_HUNDRED : undefined,
                    })}
                  />
                </InputGroup>

                <FormErrorMessage>{errors?.tips_percentage?.message}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[12, 12, 6, 4]}>
              <FormControl
                id={dictionary().COORDINATES}
                isInvalid={!!errors.coordinates || error?.data?.message[1] === 'location.longitude must be a number conforming to the specified constraints'}
              >
                <FormLabel>
                  {dictionary().COORDINATES}
                  <Reqiured />
                </FormLabel>

                <InputGroup>
                  <Input
                    type='text'
                    {...register('coordinates', {
                      required: dictionary().REQUIRED,
                      validate: (value) => value.length != 0 || dictionary().REQUIRED,
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>{errors?.coordinates?.message}</FormErrorMessage>
                <FormErrorMessage>
                  {error?.data?.message[1] === 'location.longitude must be a number conforming to the specified constraints' &&
                    dictionary().INVALID_COORDINATES}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[12, 12, 6, 4]}>
              <FormControl id={dictionary().FUEL_TYPES} isInvalid={!!errors?.availableFuelTypeIds}>
                <FormLabel>
                  {dictionary().FUEL_TYPES}
                  <Reqiured />
                </FormLabel>

                <Controller
                  name={`availableFuelTypeIds`}
                  rules={{ required: dictionary().REQUIRED }}
                  control={control}
                  render={({ field: { onChange, value } }: any) => (
                    <AutoCompleteInput
                      dataTest={dictionary().FUEL_TYPES}
                      isInvalid={!!errors?.availableFuelTypeIds}
                      onChange={onChange}
                      value={value}
                      searchRoute='/fuel-types'
                      singleRoute='/fuel-types'
                      valueFormatter={(option) => option?.id}
                      displayFormatter={(option) => option?.name?.ar}
                      multiple={true}
                    />
                  )}
                />
                <FormErrorMessage>{errors?.availableFuelTypeIds?.message}</FormErrorMessage>
              </FormControl>
            </GridItem>
          </Grid>
        </Card>
        <Card p='8' mt={'25px'}>
          <Grid templateColumns='repeat(12, 1fr)' gap={6} mt={['0', '0', '10px', '10px']}>
            <GridItem colSpan={12} pb={5}>
              <Text fontSize='20px' fontWeight='700'>
                {dictionary().SETTINGS}
              </Text>
              <hr style={{ margin: '10px 0' }} />
            </GridItem>
            <GridItem colSpan={[12, 12, 4, 3]}>
              <FormControl id={'Live OCR'}>
                <Controller
                  control={control}
                  name='station_config'
                  defaultValue={false}
                  render={({ field: { onChange } }) => (
                    <Checkbox data-test={'Live OCR'} isChecked={stationConfig} onChange={onChange} textTransform='capitalize' value='true'>
                      Live OCR
                    </Checkbox>
                  )}
                />
              </FormControl>
            </GridItem>
            {stationConfig && (
              <>
                <GridItem colSpan={[12, 12, 4, 3]}>
                  <FormControl isInvalid={!!errors.live_ocr}>
                    <FormLabel>
                      إعدادات Live OCR <Reqiured />
                    </FormLabel>
                    <Controller
                      name='live_ocr'
                      control={control}
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <Stack direction='row'>
                            <Radio value='allow_skip' color='purple'>
                              {dictionary().ALLOW_SKIP}
                            </Radio>
                            <Radio value='no_skip' color='purple'>
                              {dictionary().NO_SKIP}
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                      rules={{
                        required: true && dictionary().REQUIRED,
                      }}
                    />
                    <FormErrorMessage>{errors.live_ocr?.message}</FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={[12, 12, 4, 3]}>
                  <FormControl id={'auto_skip'}>
                    <Controller
                      control={control}
                      name='auto_skip'
                      defaultValue={false}
                      render={({ field: { onChange } }) => (
                        <Checkbox
                          data-test={dictionary().ALLOW_AUTO_SKIP}
                          isChecked={getValues().auto_skip}
                          onChange={onChange}
                          textTransform='capitalize'
                          value='true'
                        >
                          {dictionary().ALLOW_AUTO_SKIP} Live OCR
                        </Checkbox>
                      )}
                    />
                  </FormControl>
                </GridItem>
              </>
            )}
            <GridItem colSpan={[12, 12, 4, 3]}>
              <FormControl id={dictionary().LITRES}>
                <Controller
                  control={control}
                  name='use_liters'
                  defaultValue={false}
                  render={({ field: { onChange } }) => (
                    <Checkbox onChange={onChange} textTransform='capitalize' value='true' isChecked={getValues().use_liters}>
                      {dictionary().USE_LITERS}
                    </Checkbox>
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={[12, 12, 4, 3]}>
              <FormControl id={dictionary().STATUS}>
                <FormLabel>{dictionary().STATUS}</FormLabel>
                <Controller
                  control={control}
                  name='is_active'
                  render={({ field: { onChange } }) => (
                    <Checkbox onChange={onChange} textTransform='capitalize' value='true' isChecked={getValues().is_active}>
                      {dictionary().IS_ACTIVE}
                    </Checkbox>
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={[12, 12, 4, 3]}>
              <FormControl id={dictionary().ODOMETER}>
                <FormLabel>{dictionary().ODOMETER}</FormLabel>
                <Controller
                  control={control}
                  name='odometer_first'
                  render={({ field: { onChange } }) => (
                    <Checkbox onChange={onChange} textTransform='capitalize' value='true' isChecked={getValues().odometer_first}>
                      {dictionary().ODOMETER_FIRST}
                    </Checkbox>
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={[12, 12, 4, 3]}>
              <FormControl id={dictionary().SHOW}>
                <FormLabel>{dictionary().SHOW}</FormLabel>
                <Controller
                  control={control}
                  name='show_for_drivers'
                  render={({ field: { onChange } }) => (
                    <Checkbox onChange={onChange} textTransform='capitalize' value='true' isChecked={getValues().show_for_drivers}>
                      {dictionary().SHOW_STATION}
                    </Checkbox>
                  )}
                />
              </FormControl>
            </GridItem>
          </Grid>
        </Card>
      </form>
    </Container>
  )
}

export default EditStation
